<script setup>
    import 'vue3-carousel/dist/carousel.css'
    import { computed } from 'vue'
    import { usePage } from '@inertiajs/vue3'

    const localItems = [
        {
            text: 'Advertisment',
            route: 'http://www.google.com',
            image: 'https://dummyimage.com/360x300/ccc/fff',
        },
    ]
    const promoItems = [
        {
            text: 'Stream the hottest stars in Adult Entertainment!',
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC43NDAuMC4wLjAuMA/scene/sexy-brunette-reagan-foxx-is-your-wet-dream-fuck-doll-32154',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/ADD_ReaganFoxx_720x600.jpg',
        },
        {
            text: 'Stream the hottest stars in Adult Entertainment!',
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC43NDEuMC4wLjAuMA/scene/busty-blonde-london-river-gets-pussy-filled-with-delivery-mans-hot-cream-32210',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/NAMCP_LondonRiver_720x600.jpg',
        },
        {
            text: 'Stream the hottest stars in Adult Entertainment!',
            route: 'https://natour.naughtyamerica.com/track/MTIwOTI5LjEwMDI1LjguOC43MjguMC4wLjAuMA',
            image: import.meta.env.VITE_CDN_URL + '/images/promo/NDIB_PROP_720x600.jpg',
        },
    ]

    const items = computed(() => {
        // Use localItems if import.meta.env.DEV is true, otherwise use promoItems
        return import.meta.env.DEV ? localItems : promoItems
    })

    const randomItem = computed(() => {
        const randomIndex = Math.floor(Math.random() * items.value.length)
        return items.value[randomIndex]
    })
    const ad = computed(() => {
        const isLogged = !!usePage().props?.auth?.user
        const isCreator = !!usePage().props?.creator?.isCreator
        if (isCreator && isCreator) {
            return usePage().props?.sidebar_creator_ad
        } else if (isLogged) {
            return usePage().props?.sidebar_user_ad
        }
        return usePage().props?.sidebar_guest_ad
    })
</script>
<template>
    <a :href="randomItem.route" v-if="!ad" class="ad-goes-here pb-5" target="_blank">
        <p
            class="mt-[79px] pb-4 text-xl font-medium leading-tight tracking-[0.038rem] text-gray-800 dark:text-white">
            {{ randomItem.text }}
        </p>
        <img class="" :width="360" :height="300" :src="randomItem.image" :alt="randomItem.text" />
    </a>
    <div v-else class="mt-4 flex h-full items-center justify-center">
        <div class="hidden sm:block" v-html="ad.desktop_html"></div>
        <div class="block sm:hidden" v-html="ad.mobile_html"></div>
    </div>
</template>
